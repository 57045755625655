.mainSection {
  height: 100vh;
  width: 100%;
}

.machineLogo {
  position: absolute;
  height: 50%;
  width: 100%;
  object-fit: contain;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.mainSection {
  height: 100vh;
  width: 100%;
  background: black;
  display: block;
  text-align: center;
  color: white;
  display: flex;
  align-items: center;
  flex-flow: wrap;
}

.heroSectionContent {
  width: 100%;
  z-index: 10;
  max-width: 800px;
  margin: auto;
  text-align: left;
}

.mainSection h1 {
  width: 100%;
  z-index: 10000;
  font-size: 4rem;
  line-height: 4rem;
  font-family: inherit;
  font-weight: 200;
  text-transform: uppercase;
  margin: 0;
}

.mainSection p {
  width: 100%;
  z-index: 10000;
  max-width: 400px;
  font-weight: 100;
}

.mingTheChain {
  display: flex;
}

button {
  padding: 0;
  border: none;
  outline: none;
  font: inherit;
  color: inherit;
  background: none;
  cursor: pointer;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ming {
  background-color: none;
  display: flex;
  border: 1px solid white;
  height: 50px;
  border-radius: 50px;
}

.ming p {
  background: white;
  height: 100%;
  margin: 0;
  border-radius: 50px;
  color: black;
  display: flex;
  align-items: center;
  font-size: 12px;
  text-align: center;
  padding: 0 10px;
  width: 100%;
  justify-content: space-around;
  text-transform: uppercase;
  font-family: inherit;
  font-weight: 200;
}

.ming span {
  width: 120px;
  padding: 0 30px;
  display: flex;
  align-items: center;
}

.whitePaper {
  min-height: 600px;
  background: url('./assets/banner.jpg');
  background-size: cover;
  background-position: top;
  position: relative;
}

.whitePaperContent {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 510px;
  background: black;
  color: white;
  top: 0;
  bottom: 0;
  max-height: 350px;
  padding: 20px;
}

.whitePaperContent h2 {
  font-size: 3rem;
  font-style: inherit;
  font-weight: 200;
  margin: 0;
}

.whitePaperContent p {
  max-width: 300px;
  font-weight: 100;
  font-family: inherit;
}

.whitePaperContent a {
  display: block;
  background: white;
  display: inline-block;
  color: black;
  text-decoration: none;
  padding: 14px;
  border-radius: 40px;
  text-transform: uppercase;
}


.menu {
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  z-index: 1000;
}

.leftSide img {
  width: 70px;
}

.rightSide img {
  margin: 0 10px;
  height: 20px;
}

.rightSide {
  display: flex;
  height: 20px;
  align-items: center;
}

.connectButton {
  border: 1px solid rgb(160, 160, 160);
  color: white;
  padding: 0.8rem 1.2rem;
  border-radius: 1.2rem;
  height: 20px;
  margin: 0 10px;
  font-size: 0.7rem;
}